<template lang="pug">
v-layout(justify-center, wrap)
  v-flex(md12)
    v-divider
    v-row.no-gutters.align-center.justify-space-between.mx-2
      v-tabs(
        v-model="tab",
        centered,
        color="primary",
        grow,
        stacked
        height="50"
        icons-and-text,
        slider-color="primary",
        slider-width="4"
      )
        v-tab( href="#dashboard" )
          v-icon mdi-view-dashboard
          span Tempo real
        v-tab( href="#logs-internos" )
          v-icon mdi-clipboard-text
          span Logs internos
        v-tab( href="#api-logs" )
          v-icon mdi-clipboard-text
          span Api Logs
        v-tab( href="#socket-logs" )
          v-icon mdi-clipboard-text
          span Socket Logs

      v-divider
    v-tabs-items(v-model="tab")
      v-divider
      v-tab-item(value="dashboard")
        csapiSocketAdministration
      v-tab-item(value="logs-internos")
        csapiInternalLogs
      v-tab-item(value="api-logs")
        csapiApiLogs
      v-tab-item(value="socket-logs")
        csapiSocketLogs

</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AdminInstrument',

  data() {
    return {
      tab: null,
      page: 'Administração',
      serverUrl: process.env.VUE_APP_CSAPI_SOCKET_URL,
    };
  },

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
  },

  mounted() {
    this.isControl(true);
    this.setTitle('CS API - Administração');
  },
};
</script>
